@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@elpassion/taco/datePickerStyles.css';

@font-face {
  font-family: 'icons';
  src: url('https://elpassion-design-system.s3.eu-west-1.amazonaws.com/fonticon.ttf')
    format('opentype');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

main {
  padding: 16px;
}

.custom-toast {
  @apply !bg-transparent !border-none !shadow-none !p-0;
}

.react-datepicker-popper .disabled\:bg-button-primaryFilled-disabled:disabled {
  @apply !bg-white;
}

.react-datepicker-popper .disabled\:border-button-primaryFilled-disabled:disabled {
  @apply !border-none;
}

#homepage-bar-time-report-select .border-dropdown-borderWidthBase {
  @apply !py-[6px] !min-h-[44px];
}

.row {
  display: flex;
  flex-direction: row;
}

.work-stats .text-progressBar-base-circle {
  color: rgb(147 197 253);
}

.work-stats .text-progressBar-base-progress {
  color: rgb(59 130 246);
}

.summary-stats .text-progressBar-base-circle {
  color: rgb(218, 226, 235);
}

.summary-stats .text-progressBar-base-progress {
  color: rgb(234, 179, 10);
}

.unpaid-vacation-stats .text-progressBar-base-circle {
  color: rgb(218, 226, 235);
}

.unpaid-vacation-stats .text-progressBar-base-progress {
  color: rgb(236, 72, 153);
}

#CookiebotWidget {
  @apply !z-30;
}

@layer utilities {
  .overflow-inherit {
    overflow: inherit;
  }
  .overflow-initial {
    overflow: initial;
  }
}

@layer utilities {
  .text-wrap {
    text-wrap: wrap;
  }
}

/* Avoid cut sidepanel content with bottom nav in chrome on ios */
@media only screen and (min-device-width: 320px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .ios-sidepanel {
    padding-bottom: 70px;
  }
}

.time-input {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
